/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import "././node_modules/bootstrap/dist/css/bootstrap.min.css"
import "././node_modules/bootstrap/dist/js/bootstrap.bundle.min"
import "./src/style/style.scss";
import "./src/style/_variables.scss";
import "./src/style/responsive.scss";
import "././static/fonts/all.min.css";
import "aos/dist/aos.css";
import ReactDOM from "react-dom/client"

export const onRouteUpdate = () => {
    window.scrollTo(0, 0);
};


export const replaceHydrateFunction = () => {

    return (element, container) => {
        const root = ReactDOM.createRoot(container)
        root.render(element)
    }
}